<template>
  <div id="mainheader">
    <header id="header" :class="{ 'headroom--unpinned': scrolled, 'headroom': isScrolled, 'isTopHeader': isTopHeader }">
      <div class="box-call-center">
        <div class="container-fluid">
          <div class="call-center d-flex justify-content-between">
            <div class="item-call">
              <img src="../assets/images/tel-phone-icon.png" alt="icon">
              <span>Колл-центр:</span>
            </div>
            <div class="item-call">
              <a href="tel:712000013" class="a-dec-none">+998 71-200-00-13</a>
            </div>
          </div>
        </div>
      </div>

      <div class="container-navbar">
        <div class="container">
          <div class="nav-block">
            <div class="logo-navbar d-flex justify-content-between align-items-center">
              <b-icon icon="list" @click="showMobileMenu" class="bi-list-icon"></b-icon>
              <div class="dropdown">
                <button class="btn-vector" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                  aria-expanded="false">
                  <img src=".././assets/images/Logo servises/Vector.png" alt="Vector" class="Vector">
                </button>

                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <div class="row">
                    <div class="col-lg-6">
                      <a href="https://edo.faktura.uz/" target="_blank" class="InVector-item d-block">
                        <img src=".././assets/images/edo.png" alt="png" class="vector-in-img"
                          style="height: 38px; width: auto">
                        <p class="InVector-description">{{ translation.translate('LandingPageHome', 'InVector-edo') }}</p>
                      </a>
                    </div>
                    <div class="col-lg-6">
                      <a href="https://report.faktura.uz/" target="_blank" class="InVector-item d-block">
                        <img src=".././assets/images/report-logo.png" alt="" class="vector-in-img"
                          style="height: 38px; width: auto">
                        <p class="InVector-description">{{ translation.translate('LandingPageHome', 'InVector-report') }}</p>
                      </a>
                    </div>

                    <div class="col-lg-6">
                      <a href="https://botcrm.faktura.uz/" target="_blank" class="InVector-item d-block">
                        <img src=".././assets/images/crm.png" alt="png" class="vector-in-img"
                          style="height: 30px; width: auto">
                        <p class="InVector-description mt-1">{{ translation.translate('LandingPageHome', 'InVector-crm') }}</p>
                      </a>
                    </div>
                    <div class="col-lg-6">
                      <a href="https://push.faktura.uz/" target="_blank" class="InVector-item d-block">
                        <img src="../assets/images/push.png" alt="png" class="vector-in-img"
                          style="height: 32px; width: auto">
                        <p class="InVector-description">{{ translation.translate('LandingPageHome', 'InVector-push') }}</p>
                      </a>
                    </div>
                    <div class="col-lg-6">
                      <a href="https://org.faktura.uz/" target="_blank" class="InVector-item d-block">
                        <img src="../assets/images/ORG.png" alt="png" class="vector-in-img"
                          style="height: 30px; width: auto">
                        <p class="InVector-description">{{ translation.translate('LandingPageHome', 'InVector-org') }}</p>
                      </a>
                    </div>
                  </div>
                  <div class="row">

                    <div class="col-lg-12 mt-4 mb-4">

                      <a class="home-redirect-btn" target="_blank" href="https://faktura.uz/"> {{
                        translation.translate('LandingPageHome', 'allProductsBtn') }}
                        <svg width="11" height="8" viewBox="0 0 11 8" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.3536 4.35355C10.5488 4.15829 10.5488 3.84171 10.3536 3.64645L7.17157 0.464466C6.97631 0.269204 6.65973 0.269204 6.46447 0.464466C6.2692 0.659728 6.2692 0.976311 6.46447 1.17157L9.29289 4L6.46447 6.82843C6.2692 7.02369 6.2692 7.34027 6.46447 7.53553C6.65973 7.7308 6.97631 7.7308 7.17157 7.53553L10.3536 4.35355ZM0 4.5L10 4.5V3.5L0 3.5L0 4.5Z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </ul>
              </div>
              <router-link :to="{ name: 'LandingPage' }"><img src="../assets/images/Logo servises/logo.png" alt="logo"
                  style="width:180px; height:auto;"></router-link>
            </div>

            <div class="navbar-box d-flex align-items-center justify-content-between">
              <ul class="box-menu">
                <router-link :to="{ name: 'posibilities' }" class="a-dec-none">
                  <li class="nav-item" :class="(this.$route.name === 'posibilities') ? ' active' : ''">
                    {{ translation.translate("LandingPageHome", "peculiaritiesNavbar") }}</li>
                </router-link>
                <router-link :to="{ name: 'price' }" class="a-dec-none">
                  <li class="nav-item"
                    :class="(this.$route.name === 'price' || this.$route.name == 'price-archive') ? ' active' : ''">
                    {{ translation.translate("LandingPageHome", "priceNavbar") }}</li>
                </router-link>
                <a href="#contact" class="a-dec-none">
                  <li class="nav-item">{{ translation.translate("LandingPageHome", "contact") }}</li>
                </a>

                <div class="dropdown">
                  <button class="dropbtn"
                    :class="(['videoLessons', 'modules', 'resources', 'articles'].includes(this.$route.name)) ? ' active' : ''">{{
                      translation.translate("LandingPageHome",
                    "Useful") }}</button><b-icon icon="chevron-down" class="down-nav-icon"></b-icon>
                  <div class="dropdown-content">
                    <router-link :to="{ name: 'videoLessons' }" class="links-navbar"
                      :class="(this.$route.name === 'videoLessons') ? ' active' : ''">{{
                        translation.translate("LandingPageHome",
                      "video-link") }}</router-link>
                    <router-link :to="{ name: 'modules' }" class="links-navbar">{{
                      translation.translate("LandingPageHome",
                      "modul-link") }}</router-link>
                    <router-link :to="{ name: 'resources' }" class="links-navbar">{{
                      translation.translate("LandingPageHome", "contactNavbar") }}</router-link>
                    <a href="https://api.faktura.uz/docs/#overview" class="links-navbar" target="_blank">API</a>
                    <router-link :to="{ name: 'articles' }" class="links-navbar">{{
                      translation.translate("LandingPageHome", "articles") }}</router-link>
                    <router-link :to="{ name: 'public-offer' }" class="links-navbar">{{
                      translation.translate("LandingPageHome", "public-offer") }}</router-link>
                  </div>
                </div>

                <select @change="_onChangeLanguage" v-model="localLanguage" class="change-select">
                  <option value="ru">Ру</option>
                  <option value="uz">Ўз</option>
                  <option value="oz">O'z</option>
                </select>
              </ul>

              <form
                action="https://account.faktura.uz/Login?ReturnUrl=http%3a%2f%2faccount.faktura.uz%2fAuthorize%3fresponse_type%3dcode%26client_id%3d12379127389123%26redirect_uri%3dhttps%253a%252f%252fapp.faktura.uz%252faccount%252fexternallogin%26state%3d%252f%26scope%3d0%252c1%252c2%252c3"
                target="_blank">
                <input class="btn-sign-in header_sign_in ml-20" @click="callGtag('Войти,login_click,click')"
                  type="submit" :value="translation.translate('LandingPageHome', 'sign')">
              </form>
            </div>
          </div>

          <transition name="slide-fade">
            <div v-show="mobileMenu" v-cloak class="navbar-collapse">
              <ul class="navbarMobile">
                <router-link :to="{ name: 'posibilities' }">
                  <li class="nav-item">{{ translation.translate("LandingPageHome", "peculiaritiesNavbar") }}</li>
                </router-link>
                <div class="TheLine"></div>
                <router-link :to="{ name: 'price' }">
                  <li class="nav-item">{{ translation.translate("LandingPageHome", "priceNavbar") }}</li>
                </router-link>
                <div class="TheLine"></div>
                <a href="#contact" class="a-dec-none">
                  <li class="nav-item">{{ translation.translate("LandingPageHome", "contact") }}</li>
                </a>
                <div class="TheLine"></div>

                <div class="dropdown">
                  <button class="dropbtn">{{ translation.translate("LandingPageHome", "Useful") }}</button>
                  <div class="dropdown-content">
                    <router-link :to="{ name: 'videoLessons' }" class="links-navbar">{{
                      translation.translate("LandingPageHome", "video-link") }}</router-link>
                    <router-link :to="{ name: 'modules' }" class="links-navbar">{{
                      translation.translate("LandingPageHome",
                      "modul-link") }}</router-link>
                    <router-link :to="{ name: 'resources' }" class="links-navbar">{{
                      translation.translate("LandingPageHome", "contactNavbar") }}</router-link>
                    <a href="https://api.faktura.uz/help/" class="links-navbar" target="_blank">API</a>
                    <router-link :to="{ name: 'articles' }" class="links-navbar">{{
                      translation.translate("LandingPageHome", "articles") }}</router-link>
                  </div>
                </div>
                <div class="TheLine"></div>
                <select @change="_onChangeLanguage" v-model="localLanguage" class="change-select">
                  <option value="ru">Ру</option>
                  <option value="uz">Ўз</option>
                  <option value="oz">O'z</option>
                </select>
              </ul>
            </div>
          </transition>

        </div>
      </div>
    </header>
  </div>
</template>

<script defer>
import translate from '../../translation/translate';
import mixins from "./../mixin";
export default {
  name: 'mainheader',
  data() {
    return {
      mobileMenu: false,
      isScrolled: false,
      scrolled: false,
      isTopHeader: true,
      scrollPosition: null,
      limitPosition: 500,
      lastPosition: 0,
      localLanguage: JSON.parse(localStorage.getItem("translation"))?.localeName || "uz",
      countForSlider: 0,
      translation: translate,
    }
  },
  mixins: [mixins],
  methods: {
    _onChangeLanguage(event) {
      translate.changeLocale(event.target.value);
      this.$emit("changeLang", event.target.value)
    },
    showMobileMenu() {
      this.mobileMenu = !this.mobileMenu
    },
    updateScroll() {
      /* eslint-disable no-debugger */
      this.scrollPosition = window.scrollY

      if (this.scrollPosition > 10) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }

      if (this.scrollPosition < 20) {
        this.isTopHeader = true;
      } else {
        this.isTopHeader = false;
      }
      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.scrolled = true;
        // move up!
      }
      if (this.lastPosition > window.scrollY) {
        this.scrolled = false;
        // move down
      }
      this.lastPosition = window.scrollY;

    },
  },
  mounted() {
    console.log(this.$route)
    window.addEventListener('scroll', this.updateScroll);
    setInterval(() => {
      if (500 < this.count) {
        document.querySelector("html body div#app section div.block-partners div.container.slider-ct div.row.slide-wr").style.transform = `translateX(${this.count++}%)`
        console.log(true)
      }
    }, 40)
  },
  created() {
    this.$emit("changeLang", JSON.parse(localStorage.getItem("translation")).localeName)
    if (window.scrollY == 0) {
      this.isTopHeader = true;
    } else {
      this.isTopHeader = false;
    }
  }
}

</script>

<style>
a {
  text-decoration: none !important;
}
</style>
